import React from 'react'
import Layout from '../components/layout'
import Category from '../components/Category'

const CategoryTemplate = ({ pageContext, location }) => {
  const category = pageContext.data
  const lang = 'ar'

  return (
    <Layout location={location} lang={lang}>
      <Category category={category} lang={lang} />
    </Layout>
  )
}

export default CategoryTemplate
